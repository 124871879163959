import React from "react";
import Root from "../components/Root";
import SEO from "../components/seo";

const Map = () => (
  <Root>
    <SEO title="Map" />
    <h1>Map</h1>
  </Root>
);

export default Map;
